import React, { memo, useEffect, useRef } from 'react';
import { graphql } from 'gatsby';

const Embed = memo(({ slice }) => {
  // slice.primary.text.text
  const box = useRef(null);

  const {
    primary: { embed },
    slice_type,
  } = slice;

  useEffect(() => {
    if (box && box?.current) {
      const div = document.createElement('div');
      div.innerHTML = embed.text;

      box.current.appendChild(div);
    }
  }, []);

  return (
    <section className={`page__${slice_type} page__content ${slice_type} container`} ref={box} />
  );
});

export default Embed;

export const query = graphql`
  fragment StaticPageDataBody1Embed on PrismicStaticPageDataBody1Embed {
    primary {
      embed {
        text
      }
    }
  }
`;
